import React, { useEffect } from 'react';
import { Container, SubContainer } from '../../components/Container';
import { Button } from '../../components/Button';
import Title from '../../components/Title';
import { CallbackWrapper } from './styles';
import { redirect } from '../../common/redirect';

const Callback = (): JSX.Element => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      redirect();
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Container>
      <SubContainer>
        <CallbackWrapper>
          <Title title="Redirecionamento">
            <p>Sua solicitação está sendo processada, continue pelo aplicativo Unicred Mobile pelo botão a seguir.</p>
          </Title>
          <Button
            className="full"
            value="Continuar no app Unicred Mobile"
            onClick={redirect}
          />
        </CallbackWrapper>
      </SubContainer>
    </Container>
  );
};

export default Callback;

import { useContext, useEffect, useState } from "react";
import { CompartilhamentoContext } from "../../../context/CompartilhamentoContext";
import { ModalInfoContent } from "./styles";


interface Props {
    title: string
}

type listType = {
    descricao: string,
    itens: string[]
}


const Information = ({ title }: Props): JSX.Element => {
    const shareContext = useContext(CompartilhamentoContext);
    const [list, setList] = useState<listType>({
        descricao: '',
        itens: ['']
    });


    useEffect(() => {
        let auxList: listType = { descricao: '', itens: [] };

        const dados = shareContext.dados.dadosCompartilhamento.dadosSolicitados;

        for (let i = 0; i < dados.length; i++) {
            if (title === dados[i].nome) {
                auxList.descricao = dados[i].descricao;
                auxList.itens.push(...dados[i].dados);
            }
        }

        setList(auxList);
    }, [title]);

    return (
        <ModalInfoContent>
            {!!list.descricao && (
                <p> {list.descricao} </p>
            )}

            <ul>
                {list.itens.map((item, itemKey) => (
                    <li key={itemKey}>{item}</li>
                ))}
            </ul>

            <p>Para maiores informações, consulte o Portal do Cidadão:</p>

            <a href="https://openbankingbrasil.org.br/escopo-de-dados-dicionario/" target="_blank" rel="noreferrer">
                Acessar Portal do Cidadão
            </a>
        </ModalInfoContent>
    );
}

export default Information;
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { Container, SubContainer } from '../../components/Container';

const Home = (): JSX.Element => {
  const history = useNavigate();
  const authContext = useContext(AuthContext);
  const [disableLogin, setDisableLogin] = useState(true);

  useEffect(() => {
    if (authContext.hasStarted) {
      if (authContext.isAuthenticated) {
        setDisableLogin(true);
        history('/carregando');
      } else {
        setDisableLogin(false);
      }
    }
  }, [authContext]);

  return (
    <Container>
      <SubContainer>{!disableLogin && authContext.login()}</SubContainer>
    </Container>
  );
};

export default Home;
